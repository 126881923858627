import { TextField } from "@material-ui/core"
import {
  Field,
  FieldProps,
  FormikValues,
  useField,
  useFormikContext
} from "formik"
import debounce from "lodash/debounce"
import get from "lodash/get"
import { useCallback, useEffect, useState } from "react"

import type { DebouncedFieldInputProps } from "./DebouncedFieldInput.d"

const DebouncedFieldInput = <T extends FormikValues>({
  name,
  label,
  debounceValue = 500,
  ...rest
}: DebouncedFieldInputProps) => {
  const { values, setFieldValue } = useFormikContext<T>()
  const [, meta] = useField<T>(name)
  const [localValue, setLocalValue] = useState<string>(get(values, name) || "")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFieldValue = useCallback(
    debounce((field, value) => {
      setFieldValue(field, value)
    }, debounceValue),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFieldValue]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    debouncedSetFieldValue(name, localValue)

    return () => {
      debouncedSetFieldValue.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue, name, debouncedSetFieldValue])

  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <TextField
          {...field}
          label={label}
          value={localValue}
          onChange={e => setLocalValue(e.target.value)}
          fullWidth
          variant="outlined"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error ? meta.error : ""}
          {...rest}
        />
      )}
    </Field>
  )
}

export default DebouncedFieldInput
